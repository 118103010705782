import http from './request'

const api = {
    login: async function (params = {}) {
        return await http.postAsync('/merchant/login', params)
    },
    queryno: async function (params) {
        return await http.postAsync('/merchant/phone_merchants', params)
    },
    home: {
        getHomeData: async function (params = {}) {
            return await http.getAsync('/merchant/orders/statistic', params)
        },
        getStatistic: async function (params = {}) {
            return await http.getAsync('/merchant/admin/index_statistic', params)
        }
    },
    order: {
        getOrderList: async function (params = {}) {
            return await http.getAsync('/merchant/orders/list', params)
        },
        getOrderStatistic: async function (params = {}) {
            return await http.getAsync('/merchant/orders/stat', params)
        },
        drawback: async function(params = {}) {
            return await http.postAsync('/api/pay/merchant_refund', params)
        },
        drawbackV2: async function (params = {}) {
            return await http.postAsync('/api/v2/pay/merchant_refund', params)
        }
    },
    settlement: {
        getSettlementList: async function (params = {}) {
            return await http.getAsync('/merchant/orders/settlement/list', params)
        },
        export: async function (params = {}, filename) {
            return await http.download("/merchant/orders/settlement/export", params, filename)
        },
    },
    count:{
        getStatistic: async function (params = {}) {
            return await http.getAsync('/merchant/orders/statistic', params)
        }
    },

    getTradeData: async function (params = {}) {
        return await http.getAsync('/merchant/orders', params)
    },
    getDeviceData: async function (params = {}) {
        return await http.getAsync('/merchant/devices', params)
    },
    editDeviceData: async function (id, params = {}) {
        return await http.postAsync(`/merchant/devices/${id}`, params)
    },
    export: async function (params = {}, filename) {
        return http.download('/merchant/orders/export', params, filename)
    },
    reSetPassword: {
        reSetMerchant: async function(params = {}) {
            return await http.post('/merchant/edit_password', params)
        }
    }
}

export default api
