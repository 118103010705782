const storeTools = { /**
     * 加入新路由到tabs列表
     * @param {*} title 
     * @param {*} name 
     * @param {*} path 
     * @returns 
     */
    setTabs: function (title, name, path) {
        let tabList = sessionStorage.getItem('tabList')
        tabList = JSON.parse(tabList)
        if (!tabList) {
            tabList = []
        } else {
            for (let i = 0; i < tabList.length; i++) {
                if (tabList[i].name == name && tabList[i].path == path) {
                    return tabList
                }
            }
        } tabList.unshift({ title: title, name: name, path: path })
        sessionStorage.setItem('tabList', JSON.stringify(tabList))

        return tabList
    },
    /**
     * 获取tabs列表
     * @returns array
     */
    getTabs: function () {
        return JSON.parse(sessionStorage.getItem('tabList'))
    },
    /**
     * 删除tab标签项
     * @param {integer} index 
     */
    removeTab: function (index) {
        let tabList = this.getTabs()
        tabList.splice(index, 1)
        sessionStorage.setItem('tabList', JSON.stringify(tabList))
    }
}

export default storeTools
