const tools = { /** 
    * 判断arr数组中是否存在p1
    * @param {*} p1 检查参数
    * @param {Array} arr 源数组
    * @returns boolean
    */
    inArray: function (p1, arr) {
        let isExists = false;
        arr.forEach(item => {
            if (item == p1) {
                isExists = true
                return
            }
        })

        return isExists;
    },
    ucFirst: function (params) {
        var str = params.toLowerCase();
        var strArr = str.split(' ');
        var result = '';
        for (var i in strArr) {
            result += strArr[i].substring(0, 1).toUpperCase() + strArr[i].substring(1) + ' ';
        }
        return result;
    }
};

export default tools;
