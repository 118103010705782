/** 
 * 路由配置页面，所有的路由统一在这里配置，统一管理
 * 
 * 格式：
 * 一级菜单没有component组件，如果要默认显示组件，在children种配置默认组件
 * 配置方式如下：
 * 无children路由配置：
 * （单页面配置，如果登录页面，404页面）
 * {
        name: 'login',                                          # 第一级导航名称
        path: '/login',                                         # 第一级导航路径,必须有左斜线 “/”
        meta: {                                                 # 路由元数据
            icon: ''                                            # 一级导航icon图标，单页面可以没有icon图标
        },
        component: () => import ('@/pages/agents/login.vue')    # 单页面组件导入
    }
 * 有children路由配置：
    {
        name: '商户管理',                                       # 第一级导航名称
        path: '/merchant',                                      # 第一级导航路径,必须有左斜线 “/”
        meta: {                                                 # 路由元数据
            icon: 'icon-shanghuguanli',                         # 一级导航icon图标
            permission: []                                      # 一级导航权限列表
        },
        component: () => import ('@/pages/layout/layout.vue'),  # layout布局组件导入
        children: [                                             # 定义children列表
            {
                name: '新增商户',                                # 二级导航名称
                path: 'new',                                     # 二级导航路径，没有斜线
                meta: {                                          # 二级导航元数据
                    transition: 'slide-right'                    # 二级导航载入动画
                    permission: []                               # 二级导航权限列表
                },
                components: {
                    merchant_new: () => import ('@/pages/merchant/new.vue') #children子组件导入
                                                                            #这里有个很重要的注意点：每个子组件的components定义的时候都要注明router-view的视图命名
                                                                                                    视图命名规则：父组件的路径（path）去掉斜线+‘_’+子组件路径（path）
                }
            }
        ]
    }
 */

const routes = [
    {
        name: '404',
        path: '/404',
        meta: {
            icon: ''
        },
        component: () => import('@/pages/error/404.vue')
    },
    {
        name: 'login',
        path: '/login',
        meta: {
            icon: ''
        },
        component: () => import('@/pages/admin/login.vue')
    },
    {
        name: '首页',
        path: '/',
        meta: {
            icon: 'icon-shouye',
            auth: 'auth',
            permission: []
        },
        redirect: '/dashboard',
        component: () => import('@/pages/layout/layout.vue'),
        children: [
            {
                name: '首页',
                path: 'dashboard',
                isshow: true,
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    dashboard: () => import('@/pages/home/dashboard.vue')
                }
            }
        ]
    },
    {
        name:'财务管理',
        path:'/financial',
        meta:{
            icon: 'icon-jiesuanguanli',
            auth: 'auth',
            permission: []
        },
        component: () => import('@/pages/layout/layout.vue'),
        children: [
            {
                name: '订单信息',
                path: 'order',
                isshow: true,
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    financial_order: () => import('@/pages/financial/order.vue')
                }
            },
            {
                name: '结算记录',
                path: 'settlement',
                isshow: true,
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    financial_settlement: () => import('@/pages/financial/settlement.vue')
                }
            }
        ]
    },
    {
        name: '统计管理',
        path: '/count',
        meta: {
            icon: 'icon-fengkongguanli',
            auth: 'auth',
            permission: []
        },
        component: () => import('@/pages/layout/layout.vue'),
        children: [
            {
                name: '交易统计',
                path: 'dealer',
                isshow: true,
                meta: {
                    transition: 'slide-right'
                },
                components: {
                    count_dealer: () => import('@/pages/count/dealer.vue')
                }
            },
            // {
            //     name: '结算记录',
            //     path: 'account',
            //     isshow: true,
            //     meta: {
            //         transition: 'slide-right'
            //     },
            //     components: {
            //         count_account: () => import('@/pages/count/account.vue')
            //     }
            // }
        ]
    },
    // {
    //     name: '班结信息',
    //     path: '/scheduling',
    //     meta: {
    //         icon: 'icon-fapiaoguanli',
    //         auth: 'auth',
    //         permission: []
    //     },
    //     component: () => import('@/pages/layout/layout.vue'),
    //     children: [
    //         {
    //             name: '班结信息',
    //             path: 'scheduling',
    //             isshow: true,
    //             meta: {
    //                 transition: 'slide-right'
    //             },
    //             components: {
    //                 scheduling_scheduling: () => import('@/pages/Scheduling/scheduling.vue')
    //             }
    //         },
    //     ]
    // },
    // {
    //     name: '营销管理',
    //     path: '/market',
    //     meta: {
    //         icon: 'icon-ad',
    //         auth: 'auth',
    //         permission: []
    //     },
    //     component: () => import('@/pages/layout/layout.vue'),
    //     children: [
    //         {
    //             name: '营销管理',
    //             path: 'marketing',
    //             isshow: true,
    //             meta: {
    //                 transition: 'slide-right'
    //             },
    //             components: {
    //                 market_marketing: () => import('@/pages/marketing/marketing.vue')
    //             }
    //         },

    //         {
    //             name: '普通账户详情',
    //             path: 'generalaccount',
    //             isshow: true,
    //             meta: {
    //                 transition: 'slide-right'
    //             },
    //             components: {
    //                 market_generalaccount: () => import('@/pages/marketing/account.vue')
    //             }
    //         }
    //     ]
    // },
    // {
    //     name: '设置',
    //     path: '/setting',
    //     meta: {
    //         icon: 'icon-dingdanguanli',
    //         auth: 'auth',
    //         permission: []
    //     },
    //     component: () => import('@/pages/layout/layout.vue'),
    //     children: [
    //         {
    //             name: '团队管理',
    //             path: 'team',
    //             isshow: true,
    //             meta: {
    //                 transition: 'slide-right'
    //             },
    //             components: {
    //                 setting_team: () => import('@/pages/setting/team.vue')
    //             }
    //         },
    //         {
    //             name: '门店管理',
    //             path: 'store',
    //             isshow: true,
    //             meta: {
    //                 transition: 'slide-right'
    //             },
    //             components: {
    //                 setting_store: () => import('@/pages/setting/store.vue')
    //             }
    //         },
    //         {
    //             name: '设备管理',
    //             path: 'machine',
    //             isshow: true,
    //             meta: {
    //                 transition: 'slide-right'
    //             },
    //             components: {
    //                 setting_machine: () => import('@/pages/setting/machine.vue')
    //             }
    //         }
    //     ]
    // }
    // {
    //     name: '商户管理',
    //     path: '/merchant',
    //     meta: {
    //         icon: 'icon-shanghuguanli',
    //         auth: 'auth',
    //         permission: []
    //     },
    //     component: () => import('@/pages/layout/layout.vue'),
    //     children: [
    //         {
    //             name: '设备管理',
    //             path: 'device',
    //             isshow: true,
    //             meta: {
    //                 transition: 'slide-right'
    //             },
    //             components: {
    //                 merchant_device: () => import('@/pages/merchant/device.vue')
    //             }
    //         },
    //         {
    //             name: '门店管理',
    //             path: 'store',
    //             isshow: true,
    //             meta: {
    //                 transition: 'slide-right'
    //             },
    //             components: {
    //                 merchant_store: () => import('@/pages/merchant/store.vue')
    //             }
    //         }
    //     ]
    // }
]

export default routes;